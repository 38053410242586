import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fd511698 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _eda7cee4 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _bde1610e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0a23bbc8 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _5b6d8505 = () => interopDefault(import('../pages/admin/categories.vue' /* webpackChunkName: "pages/admin/categories" */))
const _08b03369 = () => interopDefault(import('../pages/admin/cheats.vue' /* webpackChunkName: "pages/admin/cheats" */))
const _6c5c709e = () => interopDefault(import('../pages/admin/files.vue' /* webpackChunkName: "pages/admin/files" */))
const _13049830 = () => interopDefault(import('../pages/admin/games.vue' /* webpackChunkName: "pages/admin/games" */))
const _534bdc90 = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "pages/admin/login" */))
const _20ff912a = () => interopDefault(import('../pages/admin/pages.vue' /* webpackChunkName: "pages/admin/pages" */))
const _323423fa = () => interopDefault(import('../pages/admin/posts.vue' /* webpackChunkName: "pages/admin/posts" */))
const _4d99d49b = () => interopDefault(import('@/pages/admin/posts.vue' /* webpackChunkName: "" */))
const _e5e60068 = () => interopDefault(import('../pages/admin/settings.vue' /* webpackChunkName: "pages/admin/settings" */))
const _1733943f = () => interopDefault(import('../pages/blog/post/_id.vue' /* webpackChunkName: "pages/blog/post/_id" */))
const _708a9930 = () => interopDefault(import('../pages/cheats/_game/_cheat.vue' /* webpackChunkName: "pages/cheats/_game/_cheat" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _fd511698,
    name: "about___ru"
  }, {
    path: "/blog",
    component: _eda7cee4,
    name: "blog___ru"
  }, {
    path: "/en",
    component: _bde1610e,
    name: "index___en"
  }, {
    path: "/sitemap",
    component: _0a23bbc8,
    name: "sitemap___ru"
  }, {
    path: "/admin/categories",
    component: _5b6d8505,
    name: "admin-categories___ru"
  }, {
    path: "/admin/cheats",
    component: _08b03369,
    name: "admin-cheats___ru"
  }, {
    path: "/admin/files",
    component: _6c5c709e,
    name: "admin-files___ru"
  }, {
    path: "/admin/games",
    component: _13049830,
    name: "admin-games___ru"
  }, {
    path: "/admin/login",
    component: _534bdc90,
    name: "admin-login___ru"
  }, {
    path: "/admin/pages",
    component: _20ff912a,
    name: "admin-pages___ru"
  }, {
    path: "/admin/posts",
    component: _323423fa,
    name: "admin-posts___ru"
  }, {
    path: "/admin/posts",
    component: _4d99d49b,
    alias: "/admin/",
    name: "admin___ru"
  }, {
    path: "/admin/settings",
    component: _e5e60068,
    name: "admin-settings___ru"
  }, {
    path: "/en/about",
    component: _fd511698,
    name: "about___en"
  }, {
    path: "/en/blog",
    component: _eda7cee4,
    name: "blog___en"
  }, {
    path: "/en/sitemap",
    component: _0a23bbc8,
    name: "sitemap___en"
  }, {
    path: "/en/admin/categories",
    component: _5b6d8505,
    name: "admin-categories___en"
  }, {
    path: "/en/admin/cheats",
    component: _08b03369,
    name: "admin-cheats___en"
  }, {
    path: "/en/admin/files",
    component: _6c5c709e,
    name: "admin-files___en"
  }, {
    path: "/en/admin/games",
    component: _13049830,
    name: "admin-games___en"
  }, {
    path: "/en/admin/login",
    component: _534bdc90,
    name: "admin-login___en"
  }, {
    path: "/en/admin/pages",
    component: _20ff912a,
    name: "admin-pages___en"
  }, {
    path: "/en/admin/posts",
    component: _323423fa,
    name: "admin-posts___en"
  }, {
    path: "/en/admin/posts",
    component: _4d99d49b,
    alias: "/admin/",
    name: "admin___en"
  }, {
    path: "/en/admin/settings",
    component: _e5e60068,
    name: "admin-settings___en"
  }, {
    path: "/en/blog/post/:id?",
    component: _1733943f,
    name: "blog-post-id___en"
  }, {
    path: "/blog/post/:id?",
    component: _1733943f,
    name: "blog-post-id___ru"
  }, {
    path: "/en/cheats/:game?/:cheat?",
    component: _708a9930,
    name: "cheats-game-cheat___en"
  }, {
    path: "/cheats/:game?/:cheat?",
    component: _708a9930,
    name: "cheats-game-cheat___ru"
  }, {
    path: "/",
    component: _bde1610e,
    name: "index___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
