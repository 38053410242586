const middleware = {}

middleware['cs2-redirect'] = require('../middleware/cs2-redirect.js')
middleware['cs2-redirect'] = middleware['cs2-redirect'].default || middleware['cs2-redirect']

middleware['lang'] = require('../middleware/lang.js')
middleware['lang'] = middleware['lang'].default || middleware['lang']

middleware['lowercase-redirect'] = require('../middleware/lowercase-redirect.js')
middleware['lowercase-redirect'] = middleware['lowercase-redirect'].default || middleware['lowercase-redirect']

middleware['remove-last-slash'] = require('../middleware/remove-last-slash.js')
middleware['remove-last-slash'] = middleware['remove-last-slash'].default || middleware['remove-last-slash']

export default middleware
